{
  "name": "nax-web-workspace",
  "version": "3.13.0",
  "engines": {
    "node": "^22.13.1",
    "npm": "^10.9.2"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "lint-staged": "lint-staged",
    "stylelint": "stylelint \"**/*.{css,scss}\"",
    "stylelint:fix": "stylelint \"**/*.{css,scss}\" --fix",
    "prettier": "prettier . --check",
    "prettier:fix": "prettier . --write --list-different",
    "cleancode": "npm run lint && npm run stylelint && npm run prettier",
    "cleancode:fix": "npm run lint:fix && npm run stylelint:fix && npm run prettier:fix",
    "lighthouse": "npm run build:beta && lhci autorun",
    "test:local": "ng test --no-watch --no-progress --code-coverage --browsers=ChromeHeadless",
    "test:ci": "ng test --no-watch --no-progress --code-coverage --browsers=Headless_Chrome",
    "prepare": "husky",
    "pre-commit-hook": "npm run lint-staged && git update-index --again",
    "build:develop": "ng build nax-web-application --configuration development --output-hashing all",
    "build:release": "ng build nax-web-application --configuration release",
    "build:stage": "ng build nax-web-application --configuration stage",
    "build:developmentToStage": "ng build nax-web-application --configuration developmentToStage",
    "build:test": "ng build nax-web-application --configuration test --base-href=/",
    "build:beta": "ng build nax-web-application --configuration beta",
    "serviceWorker:check": "npm run build:stage && echo \"\\x1b[104m\\x1b[97m\n\nATTENTION: It's a good idea to use an INCOGNITO or PRIVATE window in your browser to ensure the service worker doesn't end up reading from a previous leftover state, which can cause unexpected behavior.\n\\x1b[0m\" && npx http-server -p 8080 -c-1 dist/nax-web-application/browser",
    "start:localnetwork": "ng serve --host '0.0.0.0' --disable-host-check",
    "start:developmentToStage": "ng serve --configuration developmentToStage --port 4201",
    "start:developmentToTest": "ng serve --configuration developmentToTest",
    "post-build": "npx html-minifier-terser ./dist/nax-web-application/browser/index.html -o ./dist/nax-web-application/browser/index.html --collapse-whitespace --remove-comments --minify-js true",
    "cy:run": "npx cypress run",
    "cy:run:dev": "npx cypress run --env configuration=dev",
    "cy:run:stage": "npx cypress run --env configuration=stage",
    "cy:run:serve": "npm run build:test && npx concurrently \"npx http-server dist/nax-web-application/browser/ --port=8081 -P http://localhost:8081? -a localhost\" \"npm run cy:run -- -c baseUrl=http://localhost:8081\"",
    "cy:open": "npx cypress open",
    "cy:open:dev": "npx cypress open --env configuration=dev",
    "cy:open:stage": "npx cypress open --env configuration=stage",
    "cy:open:serve": "npm run build:test && npx concurrently \"npx http-server dist/nax-web-application/browser/ --port=8081 -P http://localhost:8081? -a localhost\" \"npm run cy:open -- -c baseUrl=http://localhost:8081\"",
    "sort-translations": "node scripts/sort_json && npx prettier --write --list-different projects/nax-web-application/public/i18n/*.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "~19.1.3",
    "@angular/common": "~19.1.3",
    "@angular/compiler": "~19.1.3",
    "@angular/core": "~19.1.3",
    "@angular/forms": "~19.1.3",
    "@angular/platform-browser": "~19.1.3",
    "@angular/platform-browser-dynamic": "~19.1.3",
    "@angular/router": "~19.1.3",
    "@angular/service-worker": "~19.1.3",
    "@ngx-translate/core": "~16.0.4",
    "@ngx-translate/http-loader": "~16.0.1",
    "@primeng/themes": "^19.0.10",
    "@sentry/angular": "^9.4.0",
    "@superset-ui/embedded-sdk": "0.1.3",
    "apexcharts": "~4.4.0",
    "flag-icons": "~7.3.2",
    "leaflet": "~1.9.4",
    "leaflet-draw": "^1.0.4",
    "leaflet.markercluster": "~1.5.3",
    "modern-normalize": "~3.0.1",
    "ng-apexcharts": "~1.15.0",
    "ngx-translate-multi-http-loader": "^19.0.2",
    "primeng": "~19.0.10",
    "rxjs": "~7.8.1",
    "tslib": "~2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "~19.1.4",
    "@angular/cli": "~19.1.4",
    "@angular/compiler-cli": "~19.1.3",
    "@commitlint/cli": "~19.6.1",
    "@commitlint/config-conventional": "~19.6.0",
    "@commitlint/types": "^19.5.0",
    "@cypress/grep": "^4.1.0",
    "@faker-js/faker": "^9.6.0",
    "@lhci/cli": "~0.14.0",
    "@types/jasmine": "~5.1.5",
    "@types/leaflet": "~1.9.16",
    "@types/leaflet-draw": "^1.0.11",
    "@types/leaflet.markercluster": "~1.5.5",
    "angular-eslint": "~19.0.2",
    "concurrently": "^9.1.2",
    "cypress": "^14.0.0",
    "cypress-fixture-faker": "^1.0.4",
    "eslint": "~9.19.0",
    "eslint-config-prettier": "~10.0.1",
    "eslint-plugin-mocha": "^10.5.0",
    "html-minifier-terser": "^7.2.0",
    "http-server": "~14.1.1",
    "husky": "~9.1.7",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.4.3",
    "ng-packagr": "~19.1.1",
    "prettier": "~3.4.2",
    "stylelint": "~16.14.1",
    "stylelint-config-recess-order": "~6.0.0",
    "stylelint-config-standard": "~37.0.0",
    "stylelint-config-standard-scss": "~14.0.0",
    "typescript": "~5.6.3",
    "typescript-eslint": "~8.22.0"
  },
  "lint-staged": {
    "*.js": "eslint --cache --fix",
    "**/*.ts": [
      "eslint --fix",
      "prettier --write --list-different"
    ],
    "*.{css,scss}": "stylelint --fix",
    "*.{css,scss,md,js}": "prettier --write --list-different"
  }
}
